<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="378" height="378" viewBox="0 0 100 100" :style="transform">
    <path d="M50 50h40" stroke="#4646ef" stroke-width="4" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'LongHand',
  props: {
    minutes: { type: Number, default: 0 }
  },
  computed: {
    transform () {
      return 'transform: rotate(' + (this.minutes * 6 - 90) + 'deg)'
    }
  }
}
</script>

<style scoped>
svg {
  position: absolute;
}
</style>