<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" height="378" width="378" :style="transform">
    <path d="M50 50h23" stroke="#f05445" stroke-width="4" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ShortHand',
  props: {
    minutes: { type: Number, default: 0 },
    hours: { type: Number, default: 0 }
  },
  computed: {
    transform () {
      return 'transform: rotate(' + (this.hours * 30 + this.minutes / 2 - 90) + 'deg)'
    }
  }
}
</script>

<style scoped>
svg {
  position: absolute;
}
</style>