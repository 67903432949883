<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="378" height="378" viewBox="0 0 100 100">
    <g transform="translate(.3 -110.7)" fill="none" stroke="#000">
      <g stroke-width="1.9">
        <path
            d="M49.7 112.8v6.3M73.7 119.3l-3.2 5.4M91.2 136.8l-5.5 3.1M97.6 160.7h-6.3M91.2 184.7l-5.5-3.2M73.7 202.2l-3.2-5.5M49.7 208.6v-6.3M25.8 202.2l3.1-5.5M8.2 184.7l5.5-3.2M1.8 160.7h6.3M8.2 136.8l5.5 3.1M25.8 119.3l3.1 5.4"/>
      </g>
      <circle r="49.7" cy="160.7" cx="49.7" stroke-width=".6" stroke-linecap="square" stroke-linejoin="bevel"/>
      <g stroke-width=".9">
        <path
            d="M49.7 112.8v2.8M54.7 113.1l-.3 2.7M59.7 113.9l-.6 2.6M64.5 115.2l-.8 2.6M69.2 117l-1.1 2.5M73.7 119.3l-1.4 2.3M77.9 122l-1.6 2.2M81.8 125.1l-1.9 2M85.3 128.7l-2 1.8M88.5 132.6l-2.2 1.6M91.2 136.8l-2.4 1.3M93.5 141.3l-2.5 1M95.3 146l-2.6.8M96.6 150.8l-2.7.5M97.3 155.7l-2.7.3M97.6 160.7h-2.7M97.3 165.7l-2.7-.3M96.6 170.7l-2.7-.6M95.3 175.5l-2.6-.8M93.5 180.2l-2.5-1.1M91.2 184.7l-2.4-1.4M88.5 188.9l-2.2-1.6M85.3 192.8l-2-1.8M81.8 196.3l-1.9-2M77.9 199.5l-1.6-2.2M73.7 202.2l-1.4-2.4M69.2 204.5L68 202M64.5 206.3l-.8-2.6M59.7 207.6l-.6-2.7M54.7 208.3l-.3-2.7M49.7 208.6V206M44.7 208.3l.3-2.7M39.8 207.6l.5-2.7M35 206.3l.8-2.6M30.2 204.5l1.1-2.5M25.8 202.2l1.3-2.4M21.6 199.5l1.6-2.2M17.7 196.3l1.8-2M14.1 192.8l2-1.8M11 188.9l2.2-1.6M8.2 184.7l2.4-1.4M6 180.2l2.5-1.1M4.2 175.5l2.6-.8M2.9 170.7l2.6-.6M2.1 165.7l2.7-.3M1.8 160.7h2.7M2.1 155.7l2.7.3M2.9 150.8l2.6.5M4.2 146l2.6.8M6 141.3l2.5 1M8.2 136.8l2.4 1.3M11 132.6l2.2 1.6M14.1 128.7l2 1.8M17.7 125.1l1.8 2M21.6 122l1.6 2.2M25.8 119.3l1.3 2.3M30.2 117l1.1 2.5M35 115.2l.8 2.6M39.8 113.9l.5 2.6M44.7 113.1l.3 2.7"/>
      </g>
      <circle cy="160.7" cx="49.7" r="0.6" fill-opacity="0.9"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ClockBackground'
}
</script>

<style scoped>
svg {
  position: absolute;
}
</style>