<template>
  <div id="all">
    <div id="analog" class="clock">
      <clock-background></clock-background>
      <long-hand :minutes="minutesNum"></long-hand>
      <short-hand :hours="hoursNum" :minutes="minutesNum"></short-hand>
    </div>
    <div id="digital" class="inputs">
      <div class="border">
        <input v-model="hours" size="1">:<input size="1" v-model="minutes">
      </div>
    </div>
  </div>
</template>

<script>
import ClockBackground from './components/ClockBackground';
import LongHand from '@/components/LongHand'
import ShortHand from '@/components/ShortHand'

export default {
  name: 'App',
  components: {
    ShortHand,
    LongHand,
    ClockBackground
  },
  data() {
    return {
      hours: "08",
      minutes: "20"
    }
  },
  computed: {
    hoursNum () {
      if (isNaN(parseInt(this.hours))) return 0
      return parseInt(this.hours)
    },
    minutesNum () {
      if (isNaN(parseInt(this.minutes))) return 0
      return parseInt(this.minutes)
    },
  }
}
</script>

<style>
@import '../node_modules/reset-css/reset.css';

@font-face {
  font-family: "wwDigital";
  src: url("../public/fonts/wwDigital.ttf");
}
#all, #analog {
  width: 418px;
}
#all {
  border: 1px solid;
}
.clock {
  height: 378px;
  margin: 20px;
}
.inputs {
  font-size: 64px;
  font-family: wwDigital;
  display: flex;
  justify-content: center;
  width: 378px;
  margin: 20px;
}
.border {
  border: 3px solid black;
  border-radius: 5px;
  padding-bottom: 6px;
}
input {
  font-size: 64px;
  font-family: wwDigital;
  border: 0;
  width: 95px;
}
.inputs input:first-child {
  text-align: right;
}
</style>
